import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MasterService } from '../../service/master.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})

export class LoginComponent {
  loginusername: any;
  password: any;

  constructor(
    private router: Router,
    private MstService: MasterService,
    private toaster : ToastrService) { }

  async ngOnInit() {
    
  }

  async callloginAPi() {
    let obj = {
      "username": this.loginusername,
      "password": this.password
    };
    let result = await this.MstService.GetDataAPI(obj, "login.php");
    if (result.Key) {
      this.toaster.success('Login Sucessfully');
      this.router.navigate(['dashboard']);
    } else {
      this.toaster.error('User name And Password is wrong');
    }

  }
}
