import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http } from '@angular/http';
import { Global_Configuration } from './Global_Configuration';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  // httpClient: any;

  phpUrl() {
    // return 'http://groceryapi.harikrushnainfotech.in/'; //android
    return "http://swastikapi.1stmemories.com/"; //live
    // return 'http://localhost/studio_api/'; //home
    // return 'http://localhost:8020/grocery/'; //office



  }

  URL: string = this.phpUrl();
  constructor(private http: Http,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService) { }


  logout() {
    // remove user from local storage to log user out

    window.sessionStorage.removeItem('user_id');
    window.sessionStorage.removeItem('username');
    window.sessionStorage.removeItem('UserCode');
    window.sessionStorage.removeItem('isAdmin');
    window.sessionStorage.removeItem('strMac');
    window.sessionStorage.removeItem('IsTaskCreate');
    window.sessionStorage.removeItem('REF_Id');
    window.sessionStorage.removeItem('login_user');
    window.sessionStorage.removeItem('user_img');
    window.sessionStorage.removeItem('firstname');
    window.sessionStorage.removeItem('lastname');
    window.sessionStorage.removeItem('user_phone');
    window.sessionStorage.removeItem('user_address');
    window.sessionStorage.removeItem('email');
    window.sessionStorage.removeItem('deptid');
    window.sessionStorage.removeItem('menu');

    // SESSION.Sessions.Logout();


    // localStorage.removeItem('currentUser');
    // this.currentUserSubject.next(null);
  }

  async GetDataAPI(Obj: any, ArgsURL: any) {
    this.ngxService.start();
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Headers': 'Accept,Accept-Language,Content-Language,Content-Type',
    //     'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PUT, DELETE',
    //     'Access-Control-Allow-Origin': '*'
    //   })
    // };
    try {
      const response = await this.http.post(this.URL + ArgsURL, Obj).toPromise();
      this.ngxService.stop();
      return await response.json();
    } catch (error) {
      let ErrorJson = error.json();
      this.ngxService.stop(); 
      // this.Toaster.error(ErrorJson.ERRMSG);
      return await ErrorJson;
    }

  }


}
