import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    name: 'Master',
    url: '/master',
    icon: 'fa fa-cogs',
    children: [
      {
        name: 'Item Master',
        url: '/master/item',
        icon: 'fa fa-cogs'
      },{
        name: 'Product Master',
        url: '/master/product',
        icon: 'fa fa-product-hunt'
      },{
        name: 'Account Group Master',
        url: '/master/accgroup',
        icon: 'fa fa-users'
      },{
        name: 'Account  Master',
        url: '/master/accmaster',
        icon: 'fa fa-users'
      },
    ]
  },
  {
    name:'transaction',
    url:"/trans",
    icon : "fa fa-cart-plus",
    children:[
      {
        name: 'Purchase',
        url: '/trans/purchase',
        icon: 'fa fa-chevron-right'
      },
    ]
  }
];
